/* styles.css */
.scrollable-content {
    overflow-y: auto;
  }
  
  /* Default state of the scrollbar, which is transparent */
  .scrollable-content::-webkit-scrollbar {
    width: 8px;
  }
  
  .scrollable-content::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
  
  .scrollable-content::-webkit-scrollbar-track {
    background-color: transparent;
  }
  
  /* State of the scrollbar when the user is scrolling */
  .scrolling::-webkit-scrollbar-thumb {
    background-color: rgb(191, 191, 191); /* or any color you prefer */
    border-radius: 10px;
  }
  
  .scrolling::-webkit-scrollbar-track {
    background-color: rgb(232, 232, 232); /* or any color you prefer */
    border-radius: 10px;
  }
  